import "./bootstrap";
import "../css/app.css";

import { createSSRApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "ziggy-js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createGtm } from "@gtm-support/vue-gtm";
import * as Sentry from "@sentry/vue";

createInertiaApp({
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        const VueApp = createSSRApp({ render: () => h(App, props) });

        Sentry.init({
            app: VueApp,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
        });

        VueApp.use(plugin)
            .component("font-awesome-icon", FontAwesomeIcon)
            .use(ZiggyVue)
            .use(
                createGtm({
                    id: import.meta.env.VITE_GTM_ID,
                    debug: import.meta.env.VITE_APP_ENV === "local",
                    defer: true,
                    compatibility: true,
                }),
            )
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
